<template>
	<div class="uid-container">
		<div class="my-form-container">
			<el-form :inline="true" :model="searchForm" class="my-form">
				<el-form-item label="邮箱：">
					<el-input v-model="searchForm.email" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="UID：">
					<el-input v-model="searchForm.UID" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="企业名称：">
					<el-input v-model="searchForm.company_name" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="状  态：">
					<el-select v-model="searchForm.credit_status" placeholder="请选择">
						<el-option v-for="item in credit_status" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="BD：">
					<!-- <el-input v-model="searchForm.BD" placeholder="请输入"></el-input> -->
					<el-select v-model="searchForm.BD" placeholder="请选择" filterable>
						<el-option label="全部" value="">
						</el-option>
						<el-option label="--空白--" value="#empty">
						</el-option>
						<el-option v-for="item in BDList" :key="item.username" :label="item.username"
							:value="item.username">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="客户名称：">
					<el-input v-model="searchForm.customer_name" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="账号类型：">
					<el-select v-model="searchForm.account_type" placeholder="请选择">
						<el-option v-for="item in account_type" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="已开通二级平台：">
					<el-select v-model="searchForm.is_open" placeholder="请选择" clearable>
						<el-option label="--空--" value="0">
						</el-option>
						<el-option label="已开通" value="2">
						</el-option>
						<el-option label="未开通" value="1">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="伙伴折扣：">
					<el-input v-model="searchForm.discount" placeholder="请输入" type="number"></el-input>
				</el-form-item>
				<el-form-item label="分销账号：">
					<el-select v-model="searchForm.distribution_account" placeholder="请选择">
						<el-option label="全部" value="">
						</el-option>
						<el-option v-for="item in distribution_account_search" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注信息：">
					<el-input v-model="searchForm.note" placeholder="请输入"></el-input>
				</el-form-item>
			</el-form>
			<div class="form-button">
				<el-button style="background-color: #3B91FF; color: #FFFFFF;" @click="getDataSearch()">查询</el-button>
				<el-button style="background-color: #FFFFFF; color: #666666;" @click="searchForm = {}">重置</el-button>
				<el-button style="background-color: #FFFFFF; color: #666666;" @click="exportExcel()">导出</el-button>
			</div>
		</div>
		<div class="my-table">
			<!-- <div class="invite-button" style="margin-left: 1.7rem; margin-bottom: .8rem;border-radius: .2rem;"
				@click="openYq">
				<img src="../../assets/uid/jiahao.png">
				邀请注册
			</div> -->
			<el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName" @sort-change="avaSort"
				empty-text="抱歉，暂无数据！" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column fixed prop="email" label="邮箱" width="220">
				</el-table-column>
				<el-table-column prop="UID" label="UID" width="150">
				</el-table-column>
				<el-table-column prop="company_name" label="企业名称" width="220">
				</el-table-column>
				<el-table-column prop="customer_name" label="客户名称" width="160">
				</el-table-column>
				<el-table-column prop="distribution_account" label="分销账号" width="220">
					<template slot-scope="scope">
						{{distribution(scope.row.distribution_account)}}
					</template>
				</el-table-column>
				<el-table-column prop="credit_line_string" label="授信额度" width="120" sortable="custom">
				</el-table-column>
				<el-table-column prop="available_credit_string" label="剩余额度" width="120" sortable="custom">
				</el-table-column>
				<el-table-column prop="alarm_credit" label="余额预警值" width="120">
				</el-table-column>
				<el-table-column prop="credit_status" label="状态" width="80">
					<template slot-scope="scope">
						<div class="free-dj">
							<div :class="{'free':scope.row.credit_status == 1,'dj':scope.row.credit_status != 1}"></div>
							{{scope.row.credit_status == 1?"正常":"冻结"}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="BD" label="BD" width="80">
				</el-table-column>
				<el-table-column prop="account_type" label="账号类型" width="80">
					<template slot-scope="scope">
						{{scope.row.account_type == 1?"伙伴":"客户"}}
					</template>
				</el-table-column>
				<el-table-column prop="is_open" label="已开通二级平台" width="160">
					<template slot-scope="scope">
						{{scope.row.is_open == 2?"已开通":scope.row.is_open == 1?"未开通":""}}
					</template>
				</el-table-column>
				<el-table-column prop="discount" label="伙伴折扣" width="160">
					<template slot-scope="scope"><span
							v-if="scope.row.discount">{{scope.row.discount}}%</span></template>
				</el-table-column>
				<el-table-column prop="effective_date" label="关联时间" width="160">
				</el-table-column>
				<el-table-column prop="note" label="备注" width="120">
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="230">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="openSx(scope.row)">授信
						</el-button>
						<el-button v-if="scope.row.credit_status == 1" type="text" size="small"
							@click="openDj(scope.row)">冻结</el-button>
						<el-popconfirm v-else title="确认解冻账户吗？" @confirm="jdAcount(scope.row)">
							<el-button style="margin-left: 10px; margin-right: 10px;" type="text" size="small"
								slot="reference">解冻</el-button>
						</el-popconfirm>
						<el-button type="text" size="small" @click="openFp(scope.row)">账号分配</el-button>
						<el-button type="text" size="small" @click="openAlarm(scope.row)">预警值
						</el-button>
						<el-button type="text" size="small" @click="detailDialog = true; xqObject = scope.row">详情
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="my-page" style="justify-content: space-between; padding-left: 10px;">
				<el-button
					style="background-color: #3B91FF; color: #FFFFFF; width: 94px; height: 40px; font-size: 14px;"
					@click="downloadExcel()" :disabled="multipleSelection.length == 0">
					<div style="display: flex;align-items: center;justify-content: center;">
						<img src="../../assets/customer/expoer.png"
							style="width: 16px; height: 16px; margin-right: 4px;">
						导出
					</div>
				</el-button>
				<el-pagination background layout="prev, pager, next, sizes, jumper" @size-change="changeSize"
					:total="total" :current-page="searchForm.page" @current-change="changePage">
				</el-pagination>
			</div>
		</div>
		<el-dialog class="my-dialog invite-dialog" title="邀请注册" :visible.sync="DialogVisible" width="30%" center
			:close-on-click-modal="$store.state.modalClose">
			<el-form :model="inviteForm" ref="inviteForm" :rules="inviteRules" label-width="7.8rem">
				<el-form-item label="分销账号：" prop="distribution_account">
					<el-select v-model="inviteForm.distribution_account" placeholder="请选择分销账号">
						<el-option v-for="(item,index) in distribution_account" :label="item.label" :key="index"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="接收邮箱：" prop="email">
					<el-input v-model="inviteForm.email" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="企业名称：" prop="unit" style="display: flex; align-items: flex-start;">
					<el-input v-model="inviteForm.unit" placeholder="请输入"></el-input>
					<span style="margin-top: 10px; font-size: 8px; color: #adadad;">注：请按此格式填写：<span
							style="color: #db7c7c;">【BD】邮箱或BD-邮箱</span></span>
				</el-form-item>
				<el-form-item label="客户：" prop="customer_sid">
					<!-- <el-input v-model="inviteForm.customer_sid" placeholder="请输入"></el-input> -->
					<el-select v-model="inviteForm.customer_sid" placeholder="请选择" filterable>
						<el-option v-for="(item,index) in customerInviteList" :label="item.customer_name" :key="index"
							:value="item.sid"></el-option>
						<div class="add-select" @click="$router.push('list?addCustomer=true')">新增客户</div>
					</el-select>
				</el-form-item>
				<el-form-item label="验证码：" prop="pic_code">
					<div style="display: flex; align-items: center; height: 30px;">
						<el-input style="flex: 1;" v-model="inviteForm.pic_code" placeholder="验证码"></el-input>
						<div style="width: 100px;cursor: pointer;" @click="reSetCode">
							<img ref="codeImg"
								src="http://pin-singapore.aliyun.com/get_img?sessionid=E7D30DE4EA36B9712656928CE36B38D2&identity=fenxiao_fx.aliyun.com">
						</div>
					</div>
				</el-form-item>
			</el-form>
			<span slot="footer" class="my-dialog-footer">
				<el-button @click="DialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="commitYq">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog class="my-dialog invite-dialog" title="账号分配" :visible.sync="distributeVisible" width="30%" center
			:close-on-click-modal="$store.state.modalClose">
			<el-form :model="distributeForm" ref="distributeForm" label-width="7.8rem">
				<el-form-item label="归属BD：" prop="customer_sid">
					<!-- <el-input v-model="inviteForm.customer_sid" placeholder="请输入"></el-input> -->
					<el-select v-model="distributeForm.BD" placeholder="请选择" filterable :disabled="diableBD"
						@change="changeGSBD">
						<el-option v-for="(item,index) in BDList" :label="item.username" :key="index"
							:value="item.username"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="归属客户：" prop="distribution_account">
					<el-select v-model="distributeForm.customer_name" :disabled="distributeForm.BD =='' "
						placeholder="请选择" filterable @change="changeCus">
						<el-option v-for="(item,index) in customerList" :label="item.customer_name" :key="index"
							:value="item.customer_name"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="my-dialog-footer">
				<el-button @click="distributeVisible = false">取 消</el-button>
				<el-button type="primary" @click="commitFp">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="授信" :visible.sync="shouxinDialog" center class="my-dialog shouxin-dailog"
			:close-on-click-modal="$store.state.modalClose">
			<el-alert title="*授信管理（剩余总额=授信总额-已消费金额，此额度用于客户在阿里云的可消费额度范围）" :closable="false" type="warning" show-icon>
			</el-alert>
			<div class="jetj">
				<div>
					授信总额：<span>${{sxObject.credit_line}}USD</span>
				</div>
				<div>
					已消费金额：<span>${{sxObject.credit_line - sxObject.available_credit}}USD</span>
				</div>
				<div>
					剩余总额：<span>${{sxObject.available_credit}}USD</span>
				</div>
			</div>
			<div class="tz-content">
				<div class="tz-left">
					<div style="color: #B1BAC7; font-size: .7rem;">调整授信额度：</div>
					<el-radio v-model="sxType" :label="1">增加</el-radio>
					<el-radio v-model="sxType" :label="2">减少</el-radio>
				</div>
				<div class="tz-right">
					$
					<el-input placeholder="请输入" onkeyup="this.value=this.value.replace(/[^\d]/g,'')"
						onafterpaste="this.value=this.value.replace(/[^\d]/g,'')" v-model="sxObject.number" />USD
				</div>
				<div class="tz-left2" style="color: #B1BAC7; font-size: .7rem; text-align: right;">调整后总额为 :</div>
				<div class="tz-right">
					$
					<el-input placeholder="请输入" v-model="sxObject.afterNumber" :disabled="true" />USD
				</div>
			</div>
			<div class="confirm-button">
				<el-button @click="shouxinDialog = false">取 消</el-button>
				<el-button type="primary" @click="commitSx">确认授信</el-button>
			</div>
			<div style="font-size: .7rem; color: #B1BAC7; margin-top: .1rem;">授信记录</div>
			<div style="margin-top: .3rem;">
				<div class="sx-heard">
					<div>调整授信额度</div>
					<div>操作员</div>
					<div>操作时间</div>
				</div>
				<div class="sx-body" v-if="creditLinesList.length > 0">
					<div class="sx-item" v-for="(item, i) in creditLinesList" :key="i">
						<div>{{item.quota_operate}}USD</div>
						<div>{{item.OP}}</div>
						<div>{{item.create_time}}</div>
					</div>
				</div>
				<div v-else style="font-size: .8rem; text-align: center; margin: 8px;">
					暂无记录
				</div>
			</div>
		</el-dialog>
		<el-dialog title="冻结" :visible.sync="freezeDialog" center class="my-dialog freeze-dialog"
			:close-on-click-modal="$store.state.modalClose">
			<el-alert title="谨慎选择：此操作会关停用户的按需付费资源，不可新开资源！！" :closable="false" type="error" center show-icon>
			</el-alert>
			<el-radio style="margin-top: 1.5rem;" :label="1" v-model="rightClose">冻结额度并立即关停后付费资源</el-radio>
			<span slot="footer" class="my-dialog-footer" style="margin-top: .8rem;">
				<el-button type="primary" @click="commitDj">确认冻结</el-button>
				<el-button @click="freezeDialog = false">取 消</el-button>
			</span>
		</el-dialog>
		<el-dialog title="账号详情" :visible.sync="detailDialog" center class="my-dialog detail-dialog"
			:close-on-click-modal="$store.state.modalClose">
			<div style="display: flex;">
				<div class="flex-item">
					<div class="f-item-tiem f-i-title">
						<div>邮箱：</div>
						<div>客户名称：</div>
						<div>授信额度：</div>
						<div>BD：</div>
						<div>关联时间：</div>
						<div v-if="xqObject.account_type == 1">伙伴折扣：</div>
						<div>备注：</div>
					</div>
					<div class="f-item-tiem">
						<div>
							<el-tooltip class="item" effect="light" :content="xqObject.email" placement="bottom">
								<div>{{xqObject.email}}</div>
							</el-tooltip>
						</div>
						<div>
							<el-tooltip class="item" effect="light" :content="xqObject.customer_name"
								placement="bottom">
								<div>{{xqObject.customer_name}}</div>
							</el-tooltip>
						</div>
						<div>${{xqObject.credit_line}}USD</div>
						<div>{{xqObject.BD}}</div>
						<div>{{xqObject.effective_date}}</div>
						<div v-if="xqObject.account_type == 1">{{xqObject.discount}}%</div>
						<div class="item-bz">
							<el-input type="textarea" v-model="xqObject.note" />
						</div>
					</div>
				</div>
				<div class="flex-item">
					<div class="f-item-tiem f-i-title">
						<div>UID：</div>
						<div>状态：</div>
						<div>剩余额度：</div>
						<div>账号类型：</div>
						<div v-if="xqObject.account_type == 1">已开通二级平台：</div>
					</div>
					<div class="f-item-tiem">
						<div>{{xqObject.UID}}</div>
						<div>{{xqObject.credit_status == 1?"正常":"冻结"}}</div>
						<div>${{xqObject.available_credit}}USD</div>
						<div>{{xqObject.account_type==1?"伙伴":"客户"}}</div>
						<div v-if="xqObject.account_type == 1">{{xqObject.is_open==2?"已开通":"未开通"}}</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="my-dialog-footer">
				<el-button @click="detailDialog = false">取 消</el-button>
				<el-button type="primary" @click="changeNote">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="余额预警值" :visible.sync="alarmDialog" center class="my-dialog freeze-dialog"
			:close-on-click-modal="$store.state.modalClose">
			<div style="display: flex; align-items: center; white-space: nowrap; margin-top: 12px;">
				<span>预警值：</span>
				<el-input onkeyup="this.value=this.value.replace(/[^\d]/g,'')"
					onafterpaste="this.value=this.value.replace(/[^\d]/g,'')" v-model="alarmObject.alarm_credit">
				</el-input>
			</div>
			<span slot="footer" class="my-dialog-footer" style="margin-top: .8rem;">
				<el-button type="primary" @click="commitAlarm">确认修改</el-button>
				<el-button @click="alarmDialog = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import mixin from '../../mixins.js'
	import {
		userList,
		editSx,
		editStatus,
		inviteRegister,
		edit,
		editRecodes,
		BDList,
		// customerInviteList,
		// excel,
		checkExcel,
		disr,
		editAlarm
	} from '../../api/uid.js'
	import {
		distributionList as list
	} from '../../api/customer.js'
	import {
		numberFixed,
		downloadFileByBase64
	} from '../../utils.js'
	import {
		export_json_to_excel
	} from '@/excel/export2Excel'
	export default {
		mixins: [mixin],
		data() {
			var validateEmail = (rule, value, callback) => {
				console.log('email' + value)
				if (value == "" || !value) {
					console.log('请输入邮箱')
					callback(new Error('请输入邮箱'));
				} else {
					if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
						// this.$refs.ruleForm.validateField('checkPass');
						callback(new Error('请输入正确的邮箱'));
					} else {
						callback();
					}
				}
			};
			var validateBDEmail = (rule, value, callback) => {
				if (value == "" || !value) {
					console.log('请输入')
					callback(new Error('请输入正确格式【BD】邮箱'));
				} else {
					let l = value.indexOf('【')
					let r = value.indexOf('】')
					let c = value.indexOf('-')
					if ((l != -1 && r != -1 && l < r) || c != -1) {
						let eValue;
						if (c != -1) {
							eValue = value.split('-')[1]
						} else {
							eValue = value.split('】')[1]
						}
						console.log(eValue)
						if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(eValue)) {
							// this.$refs.ruleForm.validateField('checkPass');
							callback(new Error('请输入正确格式【BD】邮箱或BD-邮箱'));
						} else {
							callback();
						}
					} else {
						callback(new Error('请输入正确格式【BD】邮箱或BD-邮箱'));
					}
				}
			};
			return {
				DialogVisible: false, // 分配账号对话框
				inviteForm: {}, //邀请注册表单数据
				inviteRules: {
					distribution_account: [{
						required: true,
						message: '请选择分销账号',
						trigger: 'change'
					}],
					email: [{
						required: true,
						validator: validateEmail,
						trigger: 'change'
					}],
					unit: [{
						required: true,
						validator: validateBDEmail,
						trigger: 'change'
					}],
					pic_code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'change'
					}],
					customer_sid: [{
						required: true,
						message: '请选择客户',
						trigger: 'change'
					}],
				}, //邀请注册表单验证规则
				shouxinDialog: false, //授信对话框
				freezeDialog: false, //冻结对话框
				detailDialog: false, //详情对话框
				account_type: [{
					label: "全部",
					value: ""
				}, {
					label: "伙伴",
					value: 1
				}, {
					label: "客户",
					value: 2
				}],
				distribution_account: [
					{
						label: "milestonecloud@outlook.com",
						value: 1
					},
					{
						label: "loa2099@outlook.com",
						value: 2
					},
					{
						label: "cangkir@21wintech.com",
						value: 3
					},
					{
						label: "the87cloud@outlook.com",
						value: 4
					},
					{
						label: "sla99999@hotmail.com",
						value: 5
					},
					// {
					// 	label: "HK一级账号",
					// 	value: 1
					// },
					// {
					// 	label: "HK二级账号",
					// 	value: 2
					// },
					// {
					// 	label: "PH一级账号",
					// 	value: 3
					// },
					// {
					// 	label: "PH二级账号",
					// 	value: 4
					// },
					// {
					// 	label: "CDN专用",
					// 	value: 5
					// }
				],
				distribution_account_search: [
					{
						label: "milestonecloud@outlook.com",
						value: 1
					},
					{
						label: "loa2099@outlook.com",
						value: 2
					},
					{
						label: "cangkir@21wintech.com",
						value: 3
					},
					{
						label: "the87cloud@outlook.com",
						value: 4
					},
					{
						label: "sla99999@hotmail.com",
						value: 5
					},
					// {
					// 	label: "HK一级账号",
					// 	value: 1
					// },
					// {
					// 	label: "HK二级-loa",
					// 	value: 2
					// },
					// {
					// 	label: "PH一级账号",
					// 	value: 3
					// },
					// {
					// 	label: "PH二级账号",
					// 	value: 4
					// },
					// {
					// 	label: "HK二级-mst",
					// 	value: 5
					// },
					// {
					// 	label: "CDN专用",
					// 	value: 9
					// }
				],
				credit_status: [{
					label: "全部",
					value: ""
				}, {
					label: "正常",
					value: 1
				}, {
					label: "冻结",
					value: 2
				}],
				sxObject: {}, //授信加减
				sxType: 1,
				rightClose: 1,
				djObject: {}, //冻结对象
				showCode: true,
				xqObject: {},
				noteEditForm: {},
				sortAva: null, //剩余额度排序
				sortCre: null, //总额度排序
				creditLinesParams: {
					account_sid: "",
					page: 1,
					page_size: 10
				},
				creditLinesList: [], //授信记录列表
				BDList: [],
				customerInviteList: [],
				multipleSelection: [],
				distributeVisible: false,
				distributeForm: {},
				customerList: [],
				alarmDialog: false, //余额预警值弹窗
				alarmObject: {}, //余额预警修改对象
				diableBD: false,
			}
		},
		watch: {
			"sxObject.number": {
				handler(vVal) {
					console.log(vVal)
					vVal = vVal.replace(/[^\d]/g, '')
					if (this.sxType == 1) {
						this.sxObject.afterNumber = this.sxObject.credit_line + Number(vVal)
					} else {
						if (this.sxObject.credit_line - Number(vVal) < 0) {
							this.$message.error("总金额不能小于零")
							this.sxObject.number = this.sxObject.credit_line
							return
						}
						this.sxObject.afterNumber = this.sxObject.credit_line - Number(vVal)
					}
				}
			},
			"sxType": {
				handler(vVal) {
					console.log(vVal)
					if (vVal == 1) {
						this.sxObject.afterNumber = this.sxObject.credit_line + Number(this.sxObject.number)
					} else {
						if (this.sxObject.credit_line - Number(this.sxObject.number) < 0) {
							this.sxType = 1
							this.$message.error("总金额不能小于零")
							return
						}
						this.sxObject.afterNumber = this.sxObject.credit_line - Number(this.sxObject.number)
					}
				}
			},
			"distributeForm.BD": {
				handler(nVal) {
					let BD_id = ""
					for (let i in this.BDList) {
						if (this.BDList[i].username == nVal) {
							BD_id = this.BDList[i].id
							break
						}
					}
					this.getCustomerList(BD_id)
				},
			}
		},
		methods: {
			async getData() {
				if (this.sortCre) {
					this.searchForm.credit_line_sort = this.sortCre
				} else {
					delete this.searchForm['credit_line_sort']
				}
				if (this.sortAva) {
					this.searchForm.available_credit_sort = this.sortAva
				} else {
					delete this.searchForm['available_credit_sort']
				}
				console.log(this.searchForm)
				let getDataRes = await userList(this.searchForm)
				this.tableData = getDataRes.data.records
				this.tableData = this.tableData.map(e => {
					e.available_credit_string = numberFixed(e.available_credit)
					e.credit_line_string = numberFixed(e.credit_line)
					e.is_open_string = e.is_open == 2?"已开通":e.is_open == 1?"未开通":""
					e.discount_string = e.discount?e.discount + "%":""
					return e
				})

				this.total = getDataRes.data.total_count
			},
			/* 打开授信弹窗 */
			tableRowClassName({
				row
			}) {
				if (row.available_credit < 10) {
					return 'warning-row';
				}
				return '';
			},
			openSx(row) {
				this.shouxinDialog = true;
				this.sxObject = row;
				this.sxObject.afterNumber = this.sxObject.credit_line
				this.sxObject.sxType = '1'
				this.$set(this.sxObject, 'number', '0')
				this.getCreditRecodes(row.sid)
			},
			// 确认授信修改
			async commitSx() {
				console.log(this.sxObject)
				this.sxObject.credit_line = this.sxType == 1 ? Number(this.sxObject.number) : Number("-" + this
					.sxObject.number)
				await editSx(this.sxObject)
				this.shouxinDialog = false
				this.getData(this.searchForm)
				this.$message.success("更改授信成功")
			},
			// 打开冻结弹窗
			openDj(row) {
				this.djObject = row
				this.djObject.status = 2
				this.freezeDialog = true
			},
			//确认冻结
			async commitDj() {
				await editStatus(this.djObject)
				this.$message.success("冻结成功")
				this.freezeDialog = false
				this.getData(this.searchForm)
			},
			//邀请注册弹框
			openYq() {
				this.DialogVisible = true
				this.inviteForm = {}
				this.reSetCode()
			},
			//刷新验证码
			reSetCode() {
				// this.showCode = false
				this.$nextTick(() => {
					//this.$refs.codeImg.src
					this.$refs.codeImg.src =
						"http://pin-singapore.aliyun.com/get_img?sessionid=E7D30DE4EA36B9712656928CE36B38D2&identity=fenxiao_fx.aliyun.com&random=" +
						Math.random()
				})
			},
			async commitYq() {
				this.$refs["inviteForm"].validate(async (valid) => {
					if (valid) {
						for (let i = 0; i < this.customerInviteList.length; i++) {
							if (this.customerInviteList[i].sid == this.inviteForm.customer_sid) {
								this.inviteForm.customer_name = this.customerInviteList[i].customer_name
								break
							}
						}
						let res = await inviteRegister(this.inviteForm)
						if (res.data.err_code) {
							this.$message.error(res.data.err_msg)
						} else {
							this.$message.success('邀请成功')
							this.DialogVisible = false
							this.getData()
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async jdAcount(row) {
				console.log("1223")
				let data = {
					sid: row.sid,
					status: 1
				}
				await editStatus(data)
				this.$message.success("解冻成功")
				this.getData()
			},
			async changeNote() {
				for (let key in this.xqObject) {
					if (!this.xqObject[key] || this.xqObject[key] == null || this.xqObject[key] == "null") {
						this.xqObject[key] = ""
					}
				}
				console.log(this.xqObject)
				await edit(this.xqObject)
				this.$message.success('修改成功')
				this.detailDialog = false
				this.getData()
			},
			async avaSort(e) {
				this.sortAva = null
				this.sortCre = null
				console.log(e)
				if (e.prop == "credit_line_string") {
					console.log(1)
					if (e.order == "ascending") {
						console.log(2)
						this.sortCre = 'asc'
					} else if (e.order == "descending") {
						console.log(3)
						this.sortCre = 'desc'
					} else {

						console.log(4)
						this.sortCre = null
					}
				}
				if (e.prop == "available_credit_string") {
					if (e.order == "ascending") {
						this.sortAva = 'asc'
					} else if (e.order == "descending") {
						this.sortAva = 'desc'
					} else {
						this.sortAva = null
					}
				}
				this.getData()
			},
			// 获取授信记录
			async getCreditRecodes(sid) {
				this.creditLinesList = (await editRecodes({
					account_sid: sid
				})).data.records
			},
			async exportExcel() {
				let searchParams = JSON.parse(JSON.stringify(this.searchForm))
				// searchParams.type = 'getFile'
				let che = await checkExcel(searchParams)
				console.log(che)
				if (che.err_code != 'SYS000') {
					this.$message.warning(che.err_msg)
					return
				}
				// return
				downloadFileByBase64(che.data.data, 'UID账号列表.xlsx')
				this.$message.success('导出成功')
				// let res = await excel(searchParams)
				// const fileName = 'UID账号列表.xlsx'
				// if ('download' in document.createElement('a')) { // 非IE下载
				// 	const blob = new Blob([res], {
				// 		type: 'application/ms-excel'
				// 	})
				// 	const elink = document.createElement('a')
				// 	elink.download = fileName
				// 	elink.style.display = 'none'
				// 	elink.href = URL.createObjectURL(blob)
				// 	document.body.appendChild(elink)
				// 	elink.click()
				// 	URL.revokeObjectURL(elink.href) // 释放URL 对象
				// 	document.body.removeChild(elink)
				// }
			},
			handleSelectionChange(e) {
				console.log(e)
				this.multipleSelection = e
				this.multipleSelection = this.multipleSelection.map(e => {
					e.credit_statusString = e.credit_status == 1 ? '正常' : "冻结"
					e.account_typeString = e.account_type == 1 ? '伙伴' : '客户'
					return e
				})
			},
			downloadExcel() {
				this.$confirm('确定下载列表文件?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// this.excelData = this.multipleSelection; // multipleSelection是一个数组，存储表格中选择的行的数据。
					this.export2Excel();
				}).catch(() => {

				});
			},
			// 数据写入excel
			export2Excel() {
				var that = this;
				// const {
				// 	export_json_to_excel
				// } = require('@/excel/export2Excel'); // 这里必须使用绝对路径，使用@/+存放export2Excel的路径
				const tHeader = ['邮箱', 'UID', '客户名称', '企业名称','分销账号', '授信额度', '剩余额度','余额预警值', '状态', 'BD', '账号类型',
				'已开通二级平台','伙伴折扣', '关联时间',
					'备注'
				]; // 导出的表头名信息
				const filterVal = ['email', 'UID', 'customer_name', 'company_name','distribution_account', 'credit_line_string',
					'available_credit_string','alarm_credit',
					'credit_statusString',
					'BD', 'account_typeString','is_open_string','discount_string', 'effective_date', 'note'
				]; // 导出的表头字段名，需要导出表格字段名
				const list = that.multipleSelection;
				const data = that.formatJson(filterVal, list);

				export_json_to_excel(tHeader, data, 'UID账号列表'); // 导出的表格名称，根据需要自己命名
			},
			// 格式转换，直接复制即可
			formatJson(filterVal, jsonData) {
				console.log(jsonData)
				return jsonData.map(v => filterVal.map(j => v[j]));
				// return []
			},
			openFp(e) {
				console.log(this.$cookies.get("userInfo").role_name)
				if (this.$cookies.get("userInfo").role_name == "BD") {
					this.diableBD = true
				}
				console.log(e)
				this.distributeForm = {
					sid: e.sid,
					BD: e.BD,
					customer_name: e.customer_name,
				}
				this.distributeVisible = true
			},
			async commitFp() {
				if (!this.distributeForm.customer_name) {
					this.$message.warning("未选择归属用户")
					return
				}
				for (let i in this.BDList) {
					if (this.BDList[i].username == this.distributeForm.BD) {
						this.distributeForm.BD_id = this.BDList[i].id
						break
					}
				}
				for (let i in this.customerList) {
					if (this.customerList[i].customer_name == this.distributeForm.customer_name) {
						this.distributeForm.customer_id = this.customerList[i].sid
						break
					}
				}
				await disr(this.distributeForm)
				this.$message.success('分配成功')
				this.distributeVisible = false
				this.getData()
			},
			changeCus(e) {
				for (let i = 0; i < this.customerList.length; i++) {
					if (this.customerList[i].customer_name == e) {
						this.distributeForm.account_type = this.customerList[i].account_type
						break
					}
				}
			},
			openAlarm(row) {
				this.alarmDialog = true
				this.alarmObject = {
					alarm_credit: row.alarm_credit,
					UID: row.UID,
					sid: row.sid
				}
				console.log(this.alarmObject)
			},
			async commitAlarm() {
				await editAlarm(this.alarmObject)
				this.$message.success("修改成功")
				this.alarmDialog = false
				this.getData()
			},
			changeBd(e) {
				this.getCustomerList(e)
			},
			getCustomerList(BD_id) {
				list({
					BD_id
				}).then(res => {
					console.log('客户列表')
					console.log(res)
					this.customerList = res.data.records
				})
			},
			/* 修改账号分配归属BD */
			changeGSBD(e) {
				console.log(e)
				this.distributeForm.customer_name = ""
			},
			/* 获取分销账号类型用于显示 */
			distribution(t) {
				let name = ""
				switch (t) {
					case 1:
						name = "milestonecloud@outlook.com"
						break
					case 2:
						name = "loa2099@outlook.com"
						break
					case 3:
						name = "cangkir@21wintech.com"
						break
					case 4:
						name = "the87cloud@outlook.com"
						break
					case 5:
						name = "sla99999@hotmail.com"
						break
					default:
						break
				}
				return name
			}
		},
		created() {
			BDList().then(res => {
				this.BDList = res.data.records
				console.log(this.BDList)
			})
			// customerInviteList({}).then(res => {
			// 	this.customerInviteList = res.data.records
			// })
		}
	}
</script>

<style lang="scss">
	.uid-container {
		.jetj {
			display: flex;
			justify-content: space-between;
			margin-top: 1.3rem;
			padding-bottom: .8rem;
			border-bottom: .1rem solid #EEEEEE;

			div {
				font-size: .7rem;
				color: #B1BAC7;

				span {
					color: #111111;
				}
			}
		}

		.tz-content {
			display: grid;
			grid-template-columns: auto 1fr;
			margin-top: .8rem;
			column-gap: .5rem;
			row-gap: .8rem;

			.tz-left {
				display: flex;
				align-items: center;
			}

			.tz-right {
				display: flex;
				align-items: center;
				font-size: .7rem;
				color: #333333;

				.el-input {
					width: 7.4rem;
					margin: 0 .4rem;
					font-size: .7rem;
				}

				.is-disabled {
					input {
						color: #111111;
					}
				}
			}

			.tz-left2 {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}

		.confirm-button {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 1.9rem;

			.el-button {
				width: 4.3rem;
				height: 1.7rem;
				font-size: .7rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.sx-heard {
			font-size: .7rem;
			display: flex;
			text-align: center;
			color: #333333;
			background-color: #F1F1F1;
			padding: .5rem 0;
			padding-right: .5rem;

			div:nth-child(3) {
				text-align: left;
			}

			div {
				flex: 1;
			}
		}

		.sx-body {
			max-height: 8rem;
			overflow-y: scroll;
		}

		.sx-body::-webkit-scrollbar {
			width: .5rem;

		}

		.sx-body::-webkit-scrollbar-track {

			background-color: #FBFBFB;

			-webkit-border-radius: 2em;

			-moz-border-radius: 2em;

			border-radius: 2em;

		}

		.sx-body::-webkit-scrollbar-thumb {

			background-color: #8C8C8C;

			-webkit-border-radius: 2em;

			-moz-border-radius: 2em;

			border-radius: 2em;

		}

		.sx-item {
			font-size: .7rem;
			padding: .5rem 0;
			background-color: #FBFBFB;
			display: flex;
			color: #666666;
			text-align: center;

			div:nth-child(3) {
				text-align: left;
			}

			div {
				flex: 1;
			}
		}

		.freeze-dialog {
			.el-dialog {
				width: 30.2rem !important;
			}

			.el-dialog__body {
				padding: .6rem 1.3rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-bottom: 2.2rem;
				border-bottom: .1rem solid rgba(0, 0, 0, 0.04);
			}
		}

		.detail-dialog {
			.el-dialog {
				width: 35rem !important;
			}

			.el-dialog__body {
				padding: 1.5rem 1.7rem 1rem 1.7rem;
			}
		}

		.flex-item {
			flex: 1;
			display: flex;
			font-size: .7rem;
			color: #333333;
			flex-shrink: 0;

			.f-i-title {
				div {
					text-align: right;
					white-space: nowrap;
					color: #B1BAC7;
				}
			}

			.f-item-tiem {
				display: flex;
				flex-direction: column;

				&>div {
					margin-bottom: 1rem;
					min-height: 16px;
					white-space: nowrap;
					max-width: 150px;
				}

				div {
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.item-bz {
					position: relative;
					width: 220%;
					margin-bottom: 0;
					max-width: 1000px !important;

					div {
						max-width: 1000px;
						font-size: .7rem;
					}
				}
			}
		}

		.free-dj {
			display: flex;
			align-items: center;

			div {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				margin-right: .4rem;
				flex-shrink: 0;
			}

			.free {
				background-color: #74CB86;
			}

			.dj {
				background-color: #FF4D4F;
			}
		}

		.warning-row {
			background-color: #FFF4F4;
		}

		.note-content {
			overflow: hidden; // 溢出隐藏
			display: -webkit-box; //  自适应布局 弹性伸缩盒子
			-webkit-box-orient: vertical; //垂直排列子元素 伸缩盒子的子元素排列
			-webkit-line-clamp: 3; //最多显示几行 多出部分。。。显示
			text-overflow: ellipsis; // 显示省略号
		}
	}

	.edit-note-content {
		padding: .6rem;
		width: 14.2rem;
		box-sizing: border-box;

		.button {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: .6rem;

			.el-button--primary {
				background-color: #3B91FF;
			}
		}
	}

	.el-popover {
		padding: 0;
	}

	.el-tooltip__popper.is-light {
		box-shadow: 0px 0px .8rem rgba(0, 0, 0, 0.1500);
		border: none;
	}

	.el-tooltip__popper.is-light[x-placement^=bottom] .popper__arrow {
		border-bottom-color: none !important;
	}

	.el-tooltip__popper[x-placement^=bottom] .popper__arrow {
		border-bottom-color: none !important;
	}

	.el-tooltip__popper.is-light[x-placement^=bottom] .popper__arrow {
		border-bottom-color: none !important;
	}

	.el-tooltip__popper.is-light[x-placement^=bottom] .popper__arrow {
		border-bottom-color: #ffffff !important;
	}



	.el-scrollbar {
		position: relative;
		// padding-bottom: 20px;
	}

	.el-select-dropdown__list {
		padding-bottom: 20px;
	}

	.add-select {
		position: absolute;
		bottom: 0;
		z-index: 100000;
		background-color: #e5e5e5;
		height: 30px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		font-size: 12px;
		color: #5c5c5c;
	}
</style>
